import { render, staticRenderFns } from "./FeedDashboard.vue?vue&type=template&id=fb6e14bc"
import script from "./FeedDashboard.vue?vue&type=script&lang=js"
export * from "./FeedDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCard from 'quasar/src/components/card/QCard.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QResizeObserver,QPageContainer,QSeparator,QCard});
