<template>
  <div>
    <div>
      <div class="col row items-center" style="padding: 10px 0 10px 0">
        <div
          class="col-7 cursor-pointer"
          @click="$emit('viewPondDetail', Pond)"
        >
          <div class="row items-center">
            <div class="col q-px-sm">
              <span
                style="border-bottom: 1px solid black"
                class="text-subtitle1"
              >
                {{ Pond.name }}
              </span>
            </div>
            <div class="col-8 row items-center">
              <div v-if="LastTreatment" class="col-7 row items-center q-pl-sm">
                <div :class="$q.screen.lt.sm && 'col-12'">
                  <div
                    style="width: 25px; height: 25px"
                    class="q-mr-sm"
                    v-html="LastTreatment.icon"
                  ></div>
                </div>
                <div class="ellipsis-2-lines">
                  <span>
                    {{ numberWithCommas(LastTreatment.qty) }}
                  </span>
                  <span class="text-caption text-grey-8"
                    >{{ LastTreatment.size }}
                  </span>
                </div>
              </div>

              <div v-else class="col-7 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>

              <div v-if="CurrentTreatment" class="col q-pl-sm ellipsis-2-lines">
                <span>
                  {{ numberWithCommas(CurrentTreatment.qty) }}
                </span>
                <span class="text-caption text-grey-8"
                  >{{ CurrentTreatment.size }}
                </span>
              </div>

              <div v-else class="col text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col row items-center">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="treatPond(Pond)"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Treat</div>
              <div v-if="CurrentTreatment" style="width: 30px; height: 24px">
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Treat Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogTreatPond">
      <TreatPond
        :Action="'create'"
        :Treatment="treatment"
        :Pond="Pond"
        @close="dialogTreatPond = false"
      />
    </q-dialog>
  </div>
</template>

<script>
import TreatPond from '@/components/ponds/TreatPond.vue';

import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  getTimeOfDayIcon,
  getTimeOfDayIconFromStr,
  getTimeOfDay,
  getTimeOfDayTimeStamp
} from '@/lib/TimeOfDay';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultTreatment() {
  return {
    id: null,
    user_id: store.state.user.id,
    treatment: null,
    qty: '',
    date: +new Date() / 1000,
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'TreatPondRow',
  components: {
    TreatPond
  },
  props: {
    Pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      dialogTreatPond: false,
      getTimeOfDayIcon,
      getTimeOfDayIconFromStr,
      isDateValid,
      numberWithCommas,
      parseTimeStamp,
      poundsCopy: null,
      qtyErr: false,
      selectedTimeOfDay: getTimeOfDay(new Date() / 1000),
      timeOfDayOptions: ['Morning', 'Day', 'Evening', 'Night'],
      treatment: defaultTreatment()
    };
  },
  // mounted() {},
  methods: {
    treatPond(pond) {
      this.action = 'create';
      this.treatment = defaultTreatment();
      this.dialogTreatPond = true;
    }
  },
  computed: {
    CurrentTreatment() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial treatment, return null
      if (this.Pond.treatHistory.length === 0) {
        return null;
      }

      // find the most recent treatment
      const currTreatment = this.Pond.treatHistory.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currTreatmentDate = date.formatDate(
        currTreatment.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currTreatmentDate ? currTreatment : null;
    },
    LastTreatment() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastTreatment = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.Pond.treatHistory.length; i++) {
        const treatment = this.Pond.treatHistory[i];

        const treatDate = date.formatDate(
          new Date(treatment.date * 1000),
          'YYYY-MM-DD'
        );

        if (treatDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastTreatment = cloneObj(treatment);

          break;
        }
      }

      if (lastTreatment) {
        lastTreatment.icon = getTimeOfDayIcon(lastTreatment.date);
      }

      return lastTreatment ?? null;
    }
  }
};
</script>

<style></style>
