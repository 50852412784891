<template>
  <q-card style="width: 450px">
    <div class="text-h6 text-center q-py-sm">
      {{ Pond.name }}
    </div>
    <q-separator />

    <div class="scroll" style="max-height: calc(100vh - 80px)">
      <q-form @submit="submitOxygen()">
        <div class="q-px-lg q-pt-sm q-pb-sm">
          <div class="q-pb-sm">
            <q-input
              outlined
              dense
              label="Date"
              v-model="oxygen.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
              @focus="unfocus"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="oxygen.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div>
            <q-input
              outlined
              dense
              autogrow
              v-model="oxygen.memo"
              label="Note"
              @focus="unfocus"
            />
          </div>
        </div>

        <div class="row q-px-md">
          <div class="col-12 q-px-sm">
            <div
              :style="`
                    border: ${
                      modeMonitor ? '2px #245ad3 ' : '1px #c6c6c6 '
                    } solid;
                    border-radius: 4px;
                    cursor: text;
                    height: 41px;`"
              class="q-px-sm"
              @click="monitorClick"
            >
              <div
                class="q-pl-xs q-py-none text-caption"
                :class="modeMonitor ? 'text-primary' : 'text-grey-7'"
                style="line-height: 14px; padding-top: 2px"
              >
                Monitor
              </div>
              <div
                class="q-pl-xs q-py-none q-my-none"
                style="line-height: 18px; font-size: 16px"
              >
                {{ ReadingMonitor }}
              </div>
            </div>
          </div>

          <div class="col-12 q-pb-sm">
            <div v-if="errMonitor" class="text-caption text-negative q-pl-sm">
              Please enter a monitor reading
            </div>
          </div>

          <div class="col-12 q-px-sm">
            <div
              :style="`
                    border: ${
                      modeHandheld ? '2px #245ad3 ' : '1px #c6c6c6 '
                    } solid;
                    border-radius: 4px;
                    cursor: text;
                    height: 41px;`"
              class="q-px-sm"
              @click="handheldClick"
            >
              <div
                class="q-pl-xs q-py-none text-caption"
                :class="modeHandheld ? 'text-primary' : 'text-grey-7'"
                style="line-height: 14px; padding-top: 2px"
              >
                Handheld
              </div>
              <div
                class="q-pl-xs q-py-none q-my-none"
                style="line-height: 18px; font-size: 16px"
              >
                {{ ReadingHandheld }}
              </div>
            </div>
          </div>

          <div class="col-12 q-pb-sm">
            <div v-if="errHandheld" class="text-caption text-negative q-pl-sm">
              Please enter a handheld reading
            </div>
          </div>
        </div>
        <q-separator />

        <div class="row q-px-md q-py-sm">
          <div class="col-4" v-for="n in 9" :key="n">
            <div class="q-pa-sm">
              <q-btn
                rounded
                color="primary"
                class="full-width"
                padding="10px"
                @click="addToQty(n)"
              >
                <div class="text-h6">
                  {{ n }}
                </div>
              </q-btn>
            </div>
          </div>

          <div class="col-4 q-pa-sm">
            <q-btn
              rounded
              color="primary"
              class="full-width"
              padding="10px"
              @click="addToQty(0)"
            >
              <div class="text-h6">0</div>
            </q-btn>
          </div>

          <div class="col-4 q-pa-sm">
            <q-btn
              rounded
              color="primary"
              class="full-width"
              padding="10px"
              @click="addToQty('.')"
            >
              <div class="text-h6">.</div>
            </q-btn>
          </div>

          <div class="col-4 q-pa-sm">
            <q-btn
              rounded
              color="primary"
              class="full-width"
              padding="10px"
              @click="backspace"
            >
              <div style="padding: 2px 4px 2px 0">
                <q-icon name="backspace" size="28px" />
              </div>
            </q-btn>
          </div>
        </div>

        <q-separator />
        <div class="row q-py-lg q-px-lg">
          <div class="col q-pr-sm">
            <q-btn
              rounded
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              padding="10px md"
              class="full-width"
              v-close-popup
            />
          </div>
          <div v-if="action === 'update'" class="col q-px-sm">
            <q-btn
              rounded
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              padding="10px md"
              class="full-width"
              @click="deleteOxygen"
            />
          </div>
          <div class="col q-pl-sm">
            <q-btn
              rounded
              label="Submit"
              color="secondary"
              text-color="black"
              size="md"
              padding="10px md"
              class="full-width"
              type="submit"
            />
          </div>
        </div>
      </q-form>
    </div>
  </q-card>
</template>

<script>
import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultOxygen() {
  return {
    id: null,
    user_id: store.state.user.id,
    monitor_reading: null,
    handheld_reading: null,
    date: +new Date() / 1000,
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'OxygenPondRow',
  props: {
    Action: {
      type: String,
      required: true
    },
    Pond: {
      type: Object,
      required: true
    },
    Oxygen: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      errHandheld: false,
      errMonitor: false,
      isDateValid,
      isNumValid,
      modeHandheld: false,
      modeMonitor: false,
      numberWithCommas,
      oxygen: defaultOxygen(),
      parseTimeStamp,
      poundsCopy: null,
      readingHandheld: '',
      readingMonitor: ''
    };
  },
  mounted() {
    this.oxygen = {
      ...defaultOxygen(),
      ...cloneObj(this.Oxygen)
    };

    this.dateCopy = this.oxygen.date;
    this.oxygen.date = date.formatDate(
      new Date(this.Oxygen.date * 1000),
      'MM/DD/YYYY'
    );
    this.dateCopyStr = this.oxygen.date;

    this.readingHandheld = this.oxygen.handheld_reading?.toString() ?? '';
    this.readingMonitor = this.oxygen.monitor_reading?.toString() ?? '';

    this.modeMonitor = true;
    this.modeHandheld = false;
    this.action = this.Action;
  },
  methods: {
    addToQty(n) {
      if (!this.modeHandheld && !this.modeMonitor) {
        return;
      }

      let reading = this.modeHandheld
        ? this.readingHandheld
        : this.readingMonitor;

      // if more than 2 decimal places, return
      if (reading.split('.')[1] && reading.split('.')[1].length > 1) {
        return;
      }

      if (parseInt(reading) > 99999) {
        return;
      }

      if (reading.length > 0 && n === 0) {
        reading += n.toString();
        return;
      }

      reading = parseFloat(reading + n).toString();

      // if decimal add decimal but only if there is not already a decimal
      const decimalCount = reading.split('.').length - 1;

      if (n === '.' && decimalCount < 1) {
        // check if is NaN - catches case where user enters '.'
        if (isNaN(parseFloat(reading))) {
          reading = '0.';
          return;
        }

        reading += '.';
      }

      if (this.modeHandheld) {
        this.readingHandheld = reading;
      }

      if (this.modeMonitor) {
        this.readingMonitor = reading;
      }
    },
    backspace() {
      let reading = this.modeHandheld
        ? this.readingHandheld
        : this.readingMonitor;

      if (reading.length === 1) {
        reading = '0';
      } else {
        reading = reading.slice(0, -1);
      }

      if (this.modeHandheld) {
        this.readingHandheld = reading;
      }

      if (this.modeMonitor) {
        this.readingMonitor = reading;
      }
    },
    deleteOxygen() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this oxygen record?',
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitOxygen();
        });
    },
    handheldClick() {
      this.modeHandheld = true;
      this.modeMonitor = false;
    },
    monitorClick() {
      this.modeHandheld = false;
      this.modeMonitor = true;
    },
    submitOxygen() {
      this.errMonitor = false;
      this.errHandheld = false;

      if (this.readingMonitor === '' || this.readingMonitor === '0') {
        this.errMonitor = true;
        return;
      }

      if (this.readingHandheld === '' || this.readingHandheld === '0') {
        this.errHandheld = true;
        return;
      }

      const oxygen = cloneObj(this.oxygen);

      const dateStr = this.oxygen.date;
      oxygen.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        oxygen.date = this.dateCopy;
      }

      if (this.action === 'create') {
        oxygen.id = 0;
      }

      oxygen.guid = getUID();
      oxygen.user_id = store.state.user.user_id;
      oxygen.storeInfo.action = this.action + 'OxygenPond';
      oxygen.storeInfo.category = 'oxygenPond';
      oxygen.storeInfo.pondId = this.Pond.id;
      oxygen.monitor_reading = +parseFloat(this.readingMonitor).toFixed(2);
      oxygen.handheld_reading = +parseFloat(this.readingHandheld).toFixed(2);

      guidMatchHelper(oxygen, this.action);

      store.dispatch('publish', oxygen);

      this.$q.notify({
        message: `Entry ${
          this.action === 'create'
            ? 'Added'
            : this.action === 'update'
            ? 'Updated'
            : 'Deleted'
        }`,
        icon: 'check',
        color: this.action === 'delete' ? 'negative' : 'primary'
      });

      this.$emit('close');
    },
    unfocus() {
      this.modeHandheld = false;
      this.modeMonitor = false;
    }
  },
  computed: {
    CurrentOxygen() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial oxygen record, return null
      if (this.pond.oxygenRecords.length === 0) {
        return null;
      }

      // find the most recent oxygen record
      const currOxygen = this.pond.oxygenRecords.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currOxygenDate = date.formatDate(
        currOxygen.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currOxygenDate ? currOxygen : null;
    },
    LastTenEntries() {
      return this.pond.oxygenRecords.slice(0, 10);
    },
    LastOxygen() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastOxygen = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.pond.oxygenRecords.length; i++) {
        const oxygen = this.pond.oxygenRecords[i];

        const verifyDate = date.formatDate(
          new Date(oxygen.date * 1000),
          'YYYY-MM-DD'
        );

        if (verifyDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastOxygen = cloneObj(oxygen);

          break;
        }
      }

      return lastOxygen;
    },
    ReadingHandheld() {
      let amount = parseFloat(this.readingHandheld);

      const decimalCount = this.readingHandheld.split('.').length - 1;

      if (this.readingHandheld.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.readingHandheld === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    },
    ReadingMonitor() {
      let amount = parseFloat(this.readingMonitor);

      const decimalCount = this.readingMonitor.split('.').length - 1;

      if (this.readingMonitor.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.readingMonitor === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    }
  }
};
</script>

<style></style>
