<template>
  <q-card style="width: 450px">
    <div class="text-h6 text-center q-py-sm">
      {{ SelectedFeeder.label }}
    </div>
    <q-separator />
    <div :style="`max-height: ${store.state.pageHeight - 70}px`" class="scroll">
      <q-form @submit="fillFeederSubmit">
        <div class="q-pa-lg">
          <div class="q-pb-sm">
            <q-input
              outlined
              dense
              label="Date"
              v-model="transaction.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="fillFeederBinSelection"
              label="Select Bin"
              dense
              outlined
              :options="BinOptions"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Bin']"
            />
          </div>

          <div>
            <q-input
              outlined
              dense
              autogrow
              v-model="transaction.memo"
              label="Note"
            />
          </div>
        </div>
        <q-separator />

        <div class="row items-center justify-around text-caption q-py-xs">
          <div>Last Fill</div>
          <div>
            {{ LastFillDate }}
          </div>
          <div>
            <span>
              {{ LastFill.pounds }}
            </span>
            <span> lbs </span>
          </div>
        </div>
        <q-separator />

        <div class="q-pa-md">
          <div class="q-px-sm q-pb-sm">
            <div class="text-center text-subtitle2">Enter Lbs</div>
            <div
              class="row items-center text-h6"
              style="
                height: 50px;
                padding: 0px 20px;
                border: 1px solid black;
                border-radius: 30px;
              "
            >
              {{ PoundsDisplay }}
            </div>
            <div v-if="poundsErr" class="text-accent" style="padding: 0px 22px">
              Please enter a value
            </div>
          </div>

          <div class="row">
            <div class="col-4" v-for="n in 9" :key="n">
              <div class="q-pa-sm">
                <q-btn
                  rounded
                  color="primary"
                  class="full-width"
                  padding="10px"
                  @click="addToPounds(n)"
                >
                  <div class="text-h6">
                    {{ n }}
                  </div>
                </q-btn>
              </div>
            </div>

            <div class="col-8 q-pa-sm">
              <q-btn
                rounded
                color="primary"
                class="full-width"
                padding="10px"
                @click="addToPounds(0)"
              >
                <div class="text-h6">0</div>
              </q-btn>
            </div>

            <div class="col-4 q-pa-sm">
              <q-btn
                rounded
                color="primary"
                class="full-width"
                padding="10px"
                @click="backspace"
              >
                <div style="padding: 2px 4px 2px 0">
                  <q-icon name="backspace" size="28px" />
                </div>
              </q-btn>
            </div>
          </div>
        </div>
        <q-separator />

        <div class="row q-py-lg q-px-lg">
          <div class="col q-pr-sm">
            <q-btn
              rounded
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              padding="10px"
              class="full-width"
              v-close-popup
            />
          </div>
          <div v-if="Action === 'edit'" class="col q-px-sm">
            <q-btn
              rounded
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              padding="10px md"
              class="full-width"
              @click="deleteFeederEntry"
            />
          </div>
          <div class="col q-pl-sm">
            <q-btn
              rounded
              label="Submit"
              color="secondary"
              text-color="black"
              size="md"
              class="full-width"
              padding="10px"
              type="submit"
            />
          </div>
        </div>
      </q-form>
    </div>
  </q-card>
</template>

<script>
import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import AuthUtils from '@/lib/auth-utils';

export default {
  name: 'FillFeeder',
  props: {
    Action: {
      type: String,
      required: true
    },
    SelectedFeeder: {
      type: Object,
      required: true
    },
    BinOptions: {
      type: Array,
      required: true
    },
    Bins: {
      type: Array,
      required: true
    },
    DateSearch: {
      type: Boolean,
      default: false
    },
    DatesSelectedCopy: {
      type: Object,
      default: null
    },
    Transaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateCopy: null,
      dateCopyStr: null,
      fillFeederBinSelection: null,
      isDateValid,
      numberWithCommas,
      poundsErr: false,
      poundsCopy: null,
      store,
      transaction: {},
      transactionTons: null,
      transactionPricePerTon: null,
      usableHeight: 1000
    };
  },
  mounted() {
    this.transaction = {
      ...createTransactionObj(),
      ...cloneObj(this.Transaction)
    };

    this.dateCopy = this.transaction.date;
    this.transaction.date = date.formatDate(
      this.transaction.date * 1000,
      'MM/DD/YYYY'
    );
    this.dateCopyStr = this.transaction.date;

    this.poundsCopy = parseFloat(this.transaction.pounds);

    if (this.Action === 'create') {
      this.transaction.pounds = '0';
      this.poundsCopy = 0;
    }

    if (this.Action === 'edit') {
      this.transaction.pounds = this.transaction.pounds.toString();
    }

    this.fillFeederBinSelection = this.BinOptions.find(
      (x) => x.value === this.transaction.from_account
    );
  },
  methods: {
    addToPounds(n) {
      if (parseInt(this.transaction.pounds) > 99999) {
        return;
      }

      if (this.transaction.pounds.length > 0 && n === 0) {
        this.transaction.pounds += n.toString();
        return;
      }

      this.transaction.pounds = parseInt(
        this.transaction.pounds + n
      ).toString();
    },
    backspace() {
      if (this.transaction.pounds.length === 1) {
        this.transaction.pounds = '0';
        return;
      }

      this.transaction.pounds = this.transaction.pounds.slice(0, -1);
    },
    deleteFeederEntry() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            unelevated: true,
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            unelevated: true,
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteFeederEntryConfirmed();
        });
    },
    deleteFeederEntryConfirmed() {
      const transaction = cloneObj(this.transaction);
      transaction.storeInfo.action = 'deleteFeederEntry';
      transaction.storeInfo.category = 'feederEntries';
      transaction.storeInfo.feederId = this.SelectedFeeder.id;
      transaction.storeInfo.feederGrainId = this.SelectedFeeder.grain_id;

      transaction.storeInfo.feederBal =
        parseFloat(this.SelectedFeeder.balance) - this.poundsCopy;

      guidMatchHelper(transaction, this.action);

      // Add details to update bin info
      const bin = this.BinOptions.find(
        (x) => x.value === this.transaction.from_account
      );

      transaction.storeInfo.binId = bin.id;
      transaction.storeInfo.binGrainId = bin.value;
      transaction.storeInfo.binBal = parseFloat(bin.balance) - this.poundsCopy;

      transaction.storeInfo.reconcile = this.dialogReconcile;

      store.dispatch('publish', transaction);

      this.$q.notify({
        message: 'Entry Deleted',
        color: 'accent',
        icon: 'delete'
      });

      if (this.DateSearch) {
        console.log('dates selected');

        setTimeout(() => {
          this.$emit('datesSelected', this.DatesSelectedCopy);
          this.$emit('close');
        }, 300);
        return;
      }

      this.$emit('close');
    },
    fillFeederSubmit() {
      if (this.transaction.pounds === '0') {
        this.poundsErr = true;
        return;
      }
      this.poundsErr = false;
      const transaction = cloneObj(this.transaction);

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      if (this.Action === 'edit' && this.dateCopyStr === dateStr) {
        transaction.date = this.dateCopy;
      }

      if (this.Action === 'create') {
        transaction.id = 0;
        transaction.to_account = this.SelectedFeeder.grain_id;
      }

      transaction.from_account = this.fillFeederBinSelection.value;

      this.submitEntryHelper(transaction);
      store.dispatch('publish', transaction);

      if (this.DateSearch) {
        setTimeout(() => {
          this.$emit('datesSelected', this.DatesSelectedCopy);
          this.$emit('close');
        }, 300);
        return;
      }
      this.$emit('close');
    },
    submitEntryHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table - (mutates transaction)
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries

      transaction.storeInfo.action =
        this.Action === 'create' ? 'createFeederEntry' : 'updateFeederEntry';
      transaction.storeInfo.feederBal =
        parseFloat(this.SelectedFeeder.balance) +
        (transaction.pounds - this.poundsCopy);

      transaction.storeInfo.category = 'feederEntries';
      transaction.storeInfo.feederId = this.SelectedFeeder.id;
      transaction.storeInfo.feederGrainId = this.SelectedFeeder.grain_id;

      guidMatchHelper(transaction, this.action);

      const bin = this.BinOptions.find(
        (x) => x.value === this.fillFeederBinSelection.value
      );

      if (this.Action === 'create') {
        transaction.bin_name = bin.name;
        transaction.feed_type = bin.feedTypeId;
        transaction.feed_type_name = bin.feedTypeName;
      }

      // Add details to update bin info
      transaction.storeInfo.binId = bin.id;
      transaction.storeInfo.binGrainId = bin.value;
      transaction.storeInfo.binBal =
        parseFloat(bin.balance) + (this.poundsCopy - transaction.pounds);

      transaction.storeInfo.reconcile = this.dialogReconcile;
      transaction.feeder_name = this.SelectedFeeder.name;
    }
  },
  computed: {
    LastFill() {
      return this.SelectedFeeder?.fillUps?.[0] ?? { date: null, amount: 0 };
    },
    LastFillDate() {
      return this.LastFill.date
        ? date.formatDate(this.LastFill.date * 1000, 'ddd, MMM D')
        : '---';
    },
    PoundsDisplay() {
      if (!this.transaction.pounds) {
        return '0';
      }

      return numberWithCommas(parseFloat(this.transaction.pounds));
    }
  }
};
</script>

<style></style>
