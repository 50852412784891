<template>
  <q-card :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-md'">
    <div>
      <q-list :bordered="$q.screen.xs ? false : true" separator>
        <q-item>
          <div class="col row flex-center text-h6 text-center">
            <div>
              {{ pond.name }}
            </div>
          </div>
        </q-item>
        <q-item class="row flex-center q-px-none q-py-none">
          <div class="row justify-center">
            <q-btn
              unelevated
              label="Fish In / Out"
              color="grey-3"
              text-color="primary"
              :padding="$q.screen.xs ? '2px 6px' : '2px 8px'"
              size="md"
              class="q-my-xs"
              :class="$q.screen.xs ? 'q-mr-xs' : 'q-mr-sm'"
              @click="$emit('goToFishInOut', pond)"
            />
            <!-- <q-btn -->
            <!--   unelevated -->
            <!--   label="Treat" -->
            <!--   color="grey-3" -->
            <!--   text-color="primary" -->
            <!--   :padding="$q.screen.xs ? '2px 6px' : '2px 8px'" -->
            <!--   size="md" -->
            <!--   class="q-my-xs" -->
            <!--   :class="$q.screen.xs ? 'q-mr-xs' : 'q-mr-sm'" -->
            <!--   @click="$emit('', pond)" -->
            <!-- /> -->
            <!-- <q-btn -->
            <!--   unelevated -->
            <!--   label="Oxygen" -->
            <!--   color="grey-3" -->
            <!--   text-color="primary" -->
            <!--   :padding="$q.screen.xs ? '2px 6px' : '2px 8px'" -->
            <!--   size="md" -->
            <!--   class="q-my-xs" -->
            <!--   :class="$q.screen.xs ? 'q-mr-xs' : 'q-mr-sm'" -->
            <!--   @click="$emit('goToOxygen', pond)" -->
            <!-- /> -->
            <q-btn
              unelevated
              label="Flavor"
              color="grey-3"
              text-color="primary"
              :padding="$q.screen.xs ? '2px 6px' : '2px 8px'"
              class="q-my-xs"
              size="md"
              @click="$emit('goToFlavor', pond)"
            />
          </div>
        </q-item>

        <q-item clickable v-ripple>
          <div class="col row items-center justify-between">
            <div>Head</div>
            <div>{{ PondInfo.head.toLocaleString() }}</div>
          </div>
        </q-item>

        <q-item clickable v-ripple>
          <div class="col row items-center justify-between">
            <div>Estimated Weight</div>
            <div>{{ PondInfo.weight.toLocaleString() }}</div>
          </div>
        </q-item>

        <q-expansion-item header-class="q-pa-none">
          <template v-slot:header>
            <div class="col row items-center q-pl-md">
              Fish Groups
              <span class="q-pl-sm"> ( {{ PondInfo.numGroups }} ) </span>
            </div>
          </template>

          <q-card class="q-pl-lg q-pr-md q-pb-sm">
            <div
              v-for="group of ActiveGroups"
              :key="group.stocking_id"
              class="q-pb-sm"
            >
              <div class="text-subtitle2 q-py-xs">
                {{ group.name }}
              </div>

              <div class="q-pl-md">
                <div class="row justify-between">
                  <div>Size</div>
                  <div>{{ group.size.toLocaleString() }}</div>
                </div>
                <q-separator />
              </div>

              <div class="q-pl-md">
                <div class="row justify-between">
                  <div>Head</div>
                  <div>{{ group.head.toLocaleString() }}</div>
                </div>
                <q-separator />
              </div>

              <div class="q-pl-md">
                <div class="row justify-between">
                  <div>Estimated Weight</div>
                  <div>{{ group.weight.toLocaleString() }}</div>
                </div>
                <q-separator />
              </div>
            </div>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </q-card>
</template>

<script>
import store from '@/store';
import { decoder, numberWithCommas } from '@/lib/helpers';
import { date } from 'quasar';

export default {
  name: 'Pond-Overview',
  props: {
    pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      numberWithCommas
    };
  },
  methods: {
    getNumGroups(pond) {
      return numGroups;
    }
  },
  computed: {
    ActiveGroups() {
      return this.pond.growth.FishGroups.filter(
        (group) => group.status === 'active'
      );
    },
    PondInfo() {
      const info = {
        numGroups: 0,
        head: 0,
        weight: 0
      };

      for (const group of this.pond.growth.FishGroups) {
        if (group.status !== 'active') {
          continue;
        }

        info.numGroups++;
        info.head += group.head;
        info.weight += group.weight;
      }

      return info;
    }
  }
};
</script>

<style></style>
