<template>
  <div>
    <div>
      <div
        v-if="!displayCharts"
        class="col row text-subtitle1"
        :class="displayOverfed && 'q-py-md'"
      >
        <div
          class="col-7 cursor-pointer"
          style="padding: 20px 0 0 0"
          @click="$emit('goToPondEntries', pond)"
        >
          <div class="row">
            <div class="col q-px-sm">
              <span
                style="border-bottom: 1px solid black"
                :class="displayCharts && 'text-h6'"
              >
                {{ pond.name }}
              </span>
            </div>
            <div class="col-7 row">
              <div
                class="col-6 q-pl-sm"
                :class="LastFeedingSkipped && 'text-accent'"
                :style="LastFeedingSkipped && 'opacity: 0.6'"
              >
                {{ numberWithCommas(LastFeeding.pounds) }}
              </div>

              <div v-if="CurrentFeeding.pounds !== null" class="col-6 q-pl-sm">
                {{ numberWithCommas(CurrentFeeding.pounds) }}
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>{{ PondTFA }}</div>
              </div>
            </div>
          </div>

          <div class="row" style="height: 20px">
            <div class="col"></div>
            <div class="col-7 row">
              <div
                class="col-6 row q-pl-sm"
                :style="$q.screen.gt.xs && 'padding-right: 12px'"
              >
                <div v-if="LastFeeding.overfed === 0" class="col-12">
                  <div
                    class="bg-secondary"
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>

                <div v-else class="col-12 row">
                  <div class="col-4" style="padding-right: 2px">
                    <div
                      :class="LastFeeding.overfed > 1 ? 'bg-accent' : ''"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4" style="padding: 0 1px">
                    <div
                      :class="
                        LastFeeding.overfed > 2
                          ? 'bg-accent'
                          : LastFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4" style="padding-left: 2px">
                    <div
                      :class="
                        LastFeeding.overfed > 3
                          ? 'bg-accent'
                          : LastFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                </div>
              </div>

              <div
                class="col-6 row q-pl-sm"
                :style="$q.screen.gt.xs && 'padding-right: 12px'"
              >
                <div v-if="CurrentFeeding.overfed === 0" class="col-12">
                  <div
                    class="bg-secondary"
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>

                <div v-else class="col-12 row">
                  <div class="col-4" style="padding-right: 2px">
                    <div
                      :class="CurrentFeeding.overfed > 1 ? 'bg-accent' : ''"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4" style="padding: 0 1px">
                    <div
                      :class="
                        CurrentFeeding.overfed > 2
                          ? 'bg-accent'
                          : CurrentFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4" style="padding-left: 2px">
                    <div
                      :class="
                        CurrentFeeding.overfed > 3
                          ? 'bg-accent'
                          : CurrentFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="displayOverfed" class="col">
          <q-card class="q-py-sm q-px-md">
            <div class="text-center q-pb-xs">
              {{ SliderLabel }}
            </div>

            <q-slider
              v-model="overfedSlider"
              :disable="CurrentFeeding.pounds === null"
              :min="0"
              :max="4"
              markers
              :step="1"
              snap
              :color="SliderColor"
              track-size="8px"
              thumb-size="24px"
              @input="submitOverfedDebouncer"
            />
          </q-card>
        </div>

        <div v-if="!displayOverfed" class="col row items-center">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="feedPond()"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Feed</div>
              <div
                v-if="CurrentFeeding.pounds !== null"
                style="width: 30px; height: 24px"
              >
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>

      <div v-if="displayCharts" class="q-py-xs">
        <q-card :id="`chart-container-parent-${pond.id}`">
          <div class="row items-center q-px-md">
            <div
              class="col-7 row items-center cursor-pointer q-pb-xs"
              @click="$emit('goToPondEntries', pond)"
            >
              <div class="col q-pr-sm">
                <span
                  style="border-bottom: 1px solid black"
                  :class="displayCharts && 'text-h6'"
                >
                  {{ pond.name }}
                </span>
              </div>
            </div>

            <div v-if="!displayOverfed" class="col q-py-md">
              <q-btn
                unelevated
                color="grey-4"
                textColor="black"
                padding="10px 0px"
                class="full-width"
                size="md"
                @click="feedPond()"
                @click.stop
              >
                <div
                  class="row items-center justify-between full-width q-px-md"
                >
                  <div style="width: 24px"></div>
                  <div>Feed</div>
                  <div v-if="CurrentFeeding.pounds !== null">
                    <q-icon name="task_alt" color="primary" />
                  </div>
                  <div v-else style="width: 24px"></div>
                </div>
              </q-btn>
            </div>

            <div v-if="displayOverfed" class="col q-py-md">
              <q-card outlined class="q-py-sm q-px-md">
                <div class="text-center q-pb-xs">
                  {{ SliderLabel }}
                </div>

                <q-slider
                  v-model="overfedSlider"
                  :disable="CurrentFeeding.pounds === null"
                  :min="0"
                  :max="4"
                  markers
                  marker-labels
                  :step="1"
                  snap
                  :color="SliderColor"
                  track-size="8px"
                  thumb-size="24px"
                  @input="submitOverfedDebouncer"
                />
              </q-card>
            </div>
          </div>
          <q-separator class="full-width" />

          <div class="row text-subtitle1 q-px-md" style="padding-top: 12px">
            <div class="col-6">
              Last <span> - </span> {{ LastFeeding.pounds }}
            </div>
            <div v-if="CurrentFeeding.pounds !== null" class="col-6">
              <span v-if="feedDividerOption === 1"> Current </span>
              <span v-if="feedDividerOption === 0"> Today </span>
              <span> - </span> {{ CurrentFeeding.pounds }}
            </div>

            <div v-else class="col-6 text-grey-6 q-pl-sm">
              <div v-if="PondTFADisplay">TFA - {{ PondTFA }}</div>
            </div>

            <div class="col-12 row" style="height: 12px">
              <div class="col-6 row">
                <div class="col-6 col-sm-4 row">
                  <div v-if="LastFeeding.overfed === 0" class="col-12">
                    <div
                      class="bg-secondary"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>

                  <div v-else class="col-12 row">
                    <div class="col-4" style="padding-right: 2px">
                      <div
                        :class="LastFeeding.overfed > 1 ? 'bg-accent' : ''"
                        style="height: 8px; border-radius: 3px"
                      ></div>
                    </div>

                    <div class="col-4" style="padding: 0 1px">
                      <div
                        :class="
                          LastFeeding.overfed > 2
                            ? 'bg-accent'
                            : LastFeeding.overfed > 1
                            ? 'bg-grey-4'
                            : ''
                        "
                        style="height: 8px; border-radius: 3px"
                      ></div>
                    </div>
                    <div class="col-4" style="padding-left: 2px">
                      <div
                        :class="
                          LastFeeding.overfed > 3
                            ? 'bg-accent'
                            : LastFeeding.overfed > 1
                            ? 'bg-grey-4'
                            : ''
                        "
                        style="height: 8px; border-radius: 3px"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 row">
                <div class="col-6 col-sm-4 row">
                  <div v-if="CurrentFeeding.overfed === 0" class="col-12">
                    <div
                      class="bg-secondary"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>

                  <div v-else class="col-12 row">
                    <div class="col-4" style="padding-right: 2px">
                      <div
                        :class="CurrentFeeding.overfed > 1 ? 'bg-accent' : ''"
                        style="height: 8px; border-radius: 3px"
                      ></div>
                    </div>
                    <div class="col-4" style="padding: 0 1px">
                      <div
                        :class="
                          CurrentFeeding.overfed > 2
                            ? 'bg-accent'
                            : CurrentFeeding.overfed > 1
                            ? 'bg-grey-4'
                            : ''
                        "
                        style="height: 8px; border-radius: 3px"
                      ></div>
                    </div>
                    <div class="col-4" style="padding-left: 2px">
                      <div
                        :class="
                          CurrentFeeding.overfed > 3
                            ? 'bg-accent'
                            : CurrentFeeding.overfed > 1
                            ? 'bg-grey-4'
                            : ''
                        "
                        style="height: 8px; border-radius: 3px"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <q-separator class="full-width" />

          <div
            :id="`chart-container-${pond.id}`"
            style="height: 160px"
            class="q-pa-xs"
          >
            <FeedChart
              :chartData="pond.chartData"
              :chartId="pond.id.toString()"
            />
          </div>
        </q-card>
      </div>
    </div>

    <!-- Feed Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogFeedPond">
      <q-card style="width: 450px">
        <FeedPond
          :Action="action"
          :Pond="pond"
          :FeederSelected="FeederSelected"
          :Transaction="transaction"
          @close="dialogFeedPond = false"
        />
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FeedChart from '@/components/feed/FeedChart.vue';
import FeedPond from '@/components/feed/FeedPond.vue';

import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';
import {
  getLastByDay,
  getLastByFeeding,
  getCurrentByDay,
  getCurrentByFeeding
} from '@/lib/feedingHelpers';

export default {
  name: 'FeedPondRow',
  components: {
    FeedChart,
    FeedPond
  },
  props: {
    FeederSelected: {
      type: Object,
      required: true
    },
    pond: {
      type: Object,
      required: true
    },
    displayCharts: {
      type: Boolean,
      required: true
    },
    displayOverfed: {
      type: Boolean,
      required: true
    },
    lastFeedingDates: {
      type: Object,
      required: true
    },
    resizeWatcher: {
      type: Boolean,
      required: true
    },
    feedDividerOption: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dialogFeedPond: false,
      numberWithCommas,
      overfedSlider: 1,
      parseTimeStamp,
      poundsErr: false,
      store,
      transaction: createTransactionObj()
    };
  },
  mounted() {
    this.setOverfedSlider();
    this.onResize();

    const feedingDate = this.LastFeeding.overfedFeeding?.date;
    this.$emit('setLastFeedingDate', this.pond.id, feedingDate);
  },
  methods: {
    feedPond() {
      this.action = 'create';
      this.transaction = createTransactionObj();
      this.dialogFeedPond = true;
    },
    onResize() {
      const pondsContainer = document.getElementById('ponds-container');

      const width =
        pondsContainer.clientWidth > 600
          ? 600
          : pondsContainer.clientWidth - 36;

      const chartContainer = document.getElementById(
        `chart-container-${this.pond.id}`
      );

      if (chartContainer) {
        chartContainer.style.width = `${width}px`;
      }
    },
    setOverfedSlider() {
      this.overfedSlider = this.CurrentFeeding.overfedFeeding?.over_fed ?? 1;
    },
    submitOverfed() {
      this.action = 'edit';
      this.transaction = {
        ...createTransactionObj(),
        ...cloneObj(this.CurrentFeeding.overfedFeeding)
      };

      this.submitEntry();
    },
    submitEntry(notify) {
      const transaction = cloneObj(this.transaction);

      transaction.over_fed = this.overfedSlider;

      this.submitEntryHelper(transaction);

      store.dispatch('publish', transaction);
    },
    submitEntryHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table - (mutates transaction)
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries

      transaction.storeInfo.action = 'updatePondEntry';

      transaction.storeInfo.growth = cloneObj(this.pond.growth);

      transaction.storeInfo.category = 'pondEntries';
      transaction.storeInfo.pondId = this.pond.id;
      transaction.storeInfo.pondGrainId = this.pond.grain_id;

      guidMatchHelper(transaction, 'edit');

      const feeder = this.FeederOptions.find(
        (x) => x.value === transaction.from_account
      );

      // Add details to update feeder info
      transaction.storeInfo.feederId = feeder.id;
      transaction.storeInfo.feederGrainId = feeder.value;
      transaction.storeInfo.feederBal = parseFloat(feeder.balance);
      transaction.pond_name = this.pond.name;
    },
    submitOverfedDebouncer() {
      clearTimeout(this.submitOverfedTimeout);

      this.submitOverfedTimeout = setTimeout(() => {
        this.submitOverfed();
      }, 500);
    }
  },
  computed: {
    CurrentFeeding() {
      if (this.feedDividerOption === 0) {
        return getCurrentByDay(this.pond.feedings);
      } else {
        return getCurrentByFeeding(this.pond.feedings);
      }
    },
    FeederOptions() {
      const options = [];
      for (const feeder of store.state.farm.feeders) {
        // get the feed type of the feeder
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of feeder.fillUps) {
          if (entry.feed_type) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: feeder.id,
          balance: feeder.balance,
          label: feeder.name,
          value: feeder.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    LastFeeding() {
      if (this.feedDividerOption === 0) {
        return getLastByDay(this.pond.feedings);
      } else {
        return getLastByFeeding(this.pond.feedings);
      }
    },
    LastFeedingSkipped() {
      let lastFeedingDate = this.lastFeedingDates[this.pond.id] * 1000;
      lastFeedingDate = +new Date(
        date.startOfDate(new Date(lastFeedingDate), 'day')
      );

      for (const feedingDate of Object.values(this.lastFeedingDates)) {
        let feedingDateStart = feedingDate * 1000;
        feedingDateStart = +new Date(
          date.startOfDate(new Date(feedingDateStart), 'day')
        );

        if (feedingDateStart > lastFeedingDate) {
          return true;
        }
      }

      return false;
    },
    LastTenEntries() {
      const lastTen = [];
      for (const feeding of this.pond.feedings) {
        if (lastTen.length === 10) {
          break;
        }

        lastTen.push(feeding);
      }

      return lastTen;
    },
    PondTFA() {
      if (!this.PondTFADisplay) {
        return '';
      }

      return this.pond.growth?.targetFeedAmount ?? 0 > 0
        ? numberWithCommas(this.pond.growth?.targetFeedAmount ?? 0)
        : '0';
    },
    PondTFADisplay() {
      return store.state.farm?.settings?.display_tfa ?? true;
    },
    SliderColor() {
      return this.overfedSlider === 0
        ? 'secondary'
        : this.overfedSlider === 1
        ? 'primary'
        : 'accent';
    },
    SliderLabel() {
      return this.overfedSlider === 0
        ? 'Satiated'
        : this.overfedSlider === 1
        ? 'No Eval'
        : this.overfedSlider === 2
        ? 'Overfed 1'
        : this.overfedSlider === 3
        ? 'Overfed 2'
        : 'Overfed 3';
    }
  },
  watch: {
    LastFeeding() {
      const feedingDate = this.LastFeeding.overfedFeeding?.date;
      this.$emit('setLastFeedingDate', this.pond.id, feedingDate);
    },
    resizeWatcher() {
      this.onResize();
    },
    pond() {
      this.setOverfedSlider();
    }
  }
};
</script>

<style></style>
