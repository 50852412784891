<template>
  <div>
    <div id="adjustable">
      <div class="row">
        <q-resize-observer @resize="onResize" />

        <div class="row items-center q-pl-md">
          <q-btn
            round
            icon="arrow_back"
            color="primary"
            size="md"
            padding="12px"
            @click="$emit('back')"
          />
        </div>

        <div class="col q-px-md">
          <div class="row flex-center no-wrap q-pb-md q-pt-sm">
            <q-btn
              flat
              round
              dense
              icon="chevron_left"
              padding="2px"
              :disabled="!goToPond.previous"
              @click="$emit('goToPrevious')"
            />

            <div class="row flex-center text-center text-h5 q-px-md">
              {{ pond.name }}
            </div>

            <q-btn
              flat
              round
              dense
              icon="chevron_right"
              padding="2px"
              :disabled="!goToPond.next"
              @click="$emit('goToNext')"
            />
          </div>

          <div class="row justify-center">
            Unharvested Feed:
            {{ numberWithCommas(pond.growth.CurrentUnharvestedFeed) }}
          </div>

          <div class="row justify-center">
            Target Feed: {{ numberWithCommas(pond.growth.targetFeedAmount) }}
          </div>
        </div>

        <div class="gt-xs" style="width: 64px"></div>
      </div>
      <q-separator />

      <div class="row items-center q-px-sm q-py-sm">
        <div class="gt-xs col-auto" style="width: 92px"></div>

        <div class="col row justify-center q-pl-sm">
          <div class="col-12 row justify-center">
            <div style="width: 240px" class="q-py-sm">
              <q-btn-toggle
                v-model="toggle"
                spread
                toggle-color="primary"
                :padding="$q.screen.xs ? '12px 4px' : '12px lg'"
                :options="[
                  { label: 'Feedings', value: 'feedings' },
                  { label: 'Harvests', value: 'harvests' }
                ]"
                @click="setCustomPadding"
              />
            </div>
          </div>

          <div v-if="dateSearch" class="row items-center q-py-sm">
            <div class="q-pr-sm">
              {{ datesSelectedStr }}
            </div>
            <div>
              <q-btn
                icon="close"
                color="accent"
                size="sm"
                padding="2px"
                @click="setDefault"
              />
            </div>
          </div>
        </div>

        <div class="col-auto q-pl-md">
          <div>
            <q-btn
              color="primary"
              size="sm"
              padding="7px"
              style="width: 92px"
              @click="feedPond"
            >
              <div style="font-size: 11px">Feed Pond</div>
            </q-btn>
          </div>
          <div style="padding-top: 10px">
            <q-btn
              :color="!dateSearch ? 'grey-4' : 'accent'"
              :text-color="!dateSearch ? 'black' : 'white'"
              size="sm"
              padding="7px"
              style="width: 92px"
              :loading="DataQueryLoading"
              @click="dialogDates = true"
            >
              <div style="font-size: 11px">Date Search</div>
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <q-separator color="black" />

    <div class="row text-bold q-py-xs">
      <div class="col-auto" style="width: 70px; padding-left: 20px">Date</div>
      <div class="col text-center">
        <div v-if="toggle === 'feedings'">
          <div class="gt-sm">Feeder</div>
          <div class="lt-md">Feeder / Type</div>
        </div>
        <div v-if="toggle === 'harvests'">
          <div class="gt-sm">Processor</div>
          <div class="lt-md">Processor/Type</div>
        </div>
      </div>
      <div class="col-auto text-center" style="width: 72px">Lbs</div>
      <div class="gt-sm col text-center">Feed Type</div>
      <div class="gt-xs col text-center">Note</div>
      <div class="lt-sm col-auto" style="width: 42px">Note</div>
      <div class="col-auto" :style="`padding-right: ${customPadding}px`">
        <div v-if="toggle === 'feedings'" :style="`width: ${btnWidth}px`"></div>
      </div>
    </div>
    <q-separator color="black" />

    <div
      id="entries-container"
      class="scroll"
      :style="`height: ${usableHeight}px`"
    >
      <div
        v-if="PondEntries.length < 1"
        class="text-center text-h5 text-grey-6 q-pa-md"
      >
        No Entries
      </div>
      <q-resize-observer @resize="setCustomPadding" />

      <q-virtual-scroll
        v-if="PondEntries.length"
        :style="`height: ${usableHeight}px`"
        :items="PondEntries"
        separator
      >
        <template v-slot="{ item }">
          <PondDetailRow
            :pond="pond"
            :entry="item"
            :toggle="toggle"
            @edit="editEntry"
          />
        </template>
      </q-virtual-scroll>
    </div>

    <!-- Feed Pond Dialog -->
    <q-dialog persistent position="top" v-model="dialogFeedPond">
      <q-card style="width: 450px">
        <FeedPond
          :Action="action"
          :Pond="pond"
          :FeederSelected="FeederSelected"
          :Transaction="transaction"
          @close="closePondDialog"
        />
      </q-card>
    </q-dialog>

    <q-dialog full-height persistent position="top" v-model="dialogDates">
      <DatePicker
        :dateRangeSelection="dateRangeSelection"
        @datesSelected="datesSelected"
      />
    </q-dialog>
  </div>
</template>

<script>
import PondDetailRow from '@/components/feed/PondDetailRow.vue';
import DatePicker from '@/components/general/DatePicker.vue';
import FeedPond from '@/components/feed/FeedPond.vue';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';

export default {
  name: 'PondEntries',
  components: {
    PondDetailRow,
    DatePicker,
    FeedPond
  },
  props: {
    FeederSelected: {
      type: Object,
      required: true
    },
    pond: {
      type: Object,
      required: true
    },
    goToPond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      btnWidth: 36,
      customPadding: 14,
      dateRangeSelection: 'prev90',
      dialogDates: false,
      dialogFeedPond: false,
      dateCopy: null,
      dateCopyStr: null,
      dateSearch: false,
      datesSelectedCopy: null,
      datesSelectedStr: '',
      isDateValid,
      isNumValid,
      numberWithCommas,
      poundsCopy: null,
      store,
      toggle: 'feedings',
      transaction: {},
      usableHeight: 1000
    };
  },
  mounted() {},
  methods: {
    async closePondDialog() {
      this.dialogFeedPond = false;

      // wait 1 second - not a perfect solution,
      // but this scenario is very rare
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.dateSearch) {
        this.datesSelected(this.datesSelectedCopy);
      }
    },
    datesSelected(dates) {
      this.dateRangeSelection = dates.dateRangeSelected;
      this.datesSelectedCopy = cloneObj(dates);
      const fromStr = date.formatDate(dates.from, 'MM/DD/YYYY');
      const toStr = date.formatDate(dates.to, 'MM/DD/YYYY');
      this.datesSelectedStr = `${fromStr} - ${toStr}`;

      const from = parseInt(date.formatDate(dates.from, 'X'));
      const end = date.endOfDate(dates.to, 'day');
      const to = parseInt(date.formatDate(end, 'X'));

      const dateInfo = {
        storeInfo: {
          farmId: this.$store.state.farm.id,
          action: 'getPondEntriesByDate',
          queryProp: 'pondEntries'
        },
        from,
        to,
        pondGrainId: this.pond.grain_id
      };

      store.dispatch('queryData', dateInfo);

      this.dialogDates = false;

      this.dateSearch = true;
    },
    editEntry(entry) {
      this.action = 'edit';
      this.transaction = {
        ...createTransactionObj(),
        ...cloneObj(entry)
      };

      this.dialogFeedPond = true;
    },
    feedPond() {
      this.action = 'create';
      this.transaction = createTransactionObj();

      this.dialogFeedPond = true;
    },
    onResize() {
      const adjustableHeight =
        document.getElementById('adjustable').clientHeight;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 82 - adjustableHeight;
    },
    setCustomPadding() {
      const div = document.getElementById('entries-container');

      let hasScrollBar = div.clientHeight < this.PondEntries.length * 40;

      this.customPadding = hasScrollBar ? this.ScrollbarWidth : 0;
      this.btnWidth = this.$q.screen.gt.sm ? 44 : 36;
    },
    setDefault() {
      this.dialogDates = false;
      this.dateSearch = false;
    },
    validatePrice(val) {
      return (val && isNumValid(val)) || !val;
    }
  },
  computed: {
    PondEntries() {
      return this.dateSearch
        ? this.PondQueriedEntries
        : this.toggle === 'feedings'
        ? this.pond.feedings
        : this.pond.harvests;
    },
    PondLevelPercent() {
      return getPercentage(this.pond.balance, this.pond.capacity);
    },
    PondQueriedEntries() {
      return this.toggle === 'feedings'
        ? this.$store.state.dataQueries.pondEntries?.feedings ?? []
        : this.$store.state.dataQueries.pondEntries?.harvests ?? [];
    },
    DataQueryLoading() {
      return store.state.dataQueryLoading;
    },
    FeedType() {
      let feedType = '---';
      for (const entry of this.pond.feedings) {
        if (entry.feeder_name) {
          feedType = entry.feed_type_name;
          break;
        }
      }

      return feedType;
    },
    FeedTypes() {
      const feedTypes = [];
      for (const feedType of store.state.farm.feedTypes) {
        if (!feedType.archived) {
          feedTypes.push({
            label: feedType.name,
            value: feedType.id
          });
        }
      }
      return feedTypes;
    },
    FeederOptions() {
      const options = [];
      for (const feeder of store.state.farm.feeders) {
        // get the feed type of the feeder
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of feeder.fillUps) {
          if (entry.feed_type) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: feeder.id,
          balance: feeder.balance,
          label: feeder.name,
          value: feeder.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    ScrollbarWidth() {
      return getScrollbarWidth();
    }
  },
  watch: {
    dateSearch() {
      this.$nextTick(() => {
        this.onResize();
      });
    }
  }
};
</script>

<style></style>
