<template>
  <div>
    <div class="text-h6 text-center q-py-sm">
      {{ Pond.name }}
    </div>
    <q-separator />

    <div class="scroll" :style="`max-height: ${store.state.pageHeight - 70}px`">
      <q-form @submit="submitEntry(true)">
        <div class="q-px-lg q-pt-lg q-pb-sm">
          <div class="q-pb-sm">
            <q-input
              outlined
              dense
              label="Date"
              v-model="transaction.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div>
            <q-input
              outlined
              dense
              autogrow
              v-model="transaction.memo"
              label="Note"
            />
          </div>

          <div class="q-pt-xs">
            <div style="padding: 4px 12px 0 12px">
              <div class="text-center q-pb-xs">
                {{ SliderDialogLabel }}
              </div>

              <q-slider
                dense
                v-model="overfedDialogSlider"
                :min="0"
                :max="4"
                markers
                :step="1"
                snap
                :color="SliderDialogColor"
                track-size="10px"
                thumb-size="30px"
              />
            </div>
            <div class="row justify-center text-caption q-pb-xs">Feed Eval</div>
          </div>
        </div>
        <q-separator />

        <div class="q-pa-md">
          <div class="q-px-sm q-pb-sm">
            <div class="text-center text-subtitle2">Enter Lbs</div>
            <div
              class="row items-center text-h6"
              style="
                height: 50px;
                padding: 0px 20px;
                border: 1px solid black;
                border-radius: 30px;
              "
            >
              <span v-if="PoundsDisplay !== null">
                {{ PoundsDisplay }}
              </span>
              <span v-if="PondTFADisplay && !PoundsDisplay" class="text-grey-5">
                TFA - {{ PondTFA }}
              </span>
            </div>

            <div v-if="poundsErr" class="text-accent" style="padding: 0px 22px">
              Please enter a value
            </div>
          </div>

          <div class="row">
            <div class="col-4" v-for="n in 9" :key="n">
              <div class="q-pa-sm">
                <q-btn
                  rounded
                  color="primary"
                  class="full-width"
                  padding="10px"
                  @click="addToPounds(n)"
                >
                  <div class="text-h6">
                    {{ n }}
                  </div>
                </q-btn>
              </div>
            </div>

            <div class="col-8 q-pa-sm">
              <q-btn
                rounded
                color="primary"
                class="full-width"
                padding="10px"
                @click="addToPounds(0)"
              >
                <div class="text-h6">0</div>
              </q-btn>
            </div>

            <div class="col-4 q-pa-sm">
              <q-btn
                rounded
                color="primary"
                class="full-width"
                padding="10px"
                @click="backspace"
              >
                <div style="padding: 2px 4px 2px 0">
                  <q-icon name="backspace" size="28px" />
                </div>
              </q-btn>
            </div>
          </div>
        </div>
        <q-separator />

        <div class="row q-py-lg q-px-lg">
          <div class="col q-pr-sm">
            <q-btn
              rounded
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              padding="10px md"
              class="full-width"
              v-close-popup
            />
          </div>
          <div v-if="Action === 'edit'" class="col q-px-sm">
            <q-btn
              rounded
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              padding="10px md"
              class="full-width"
              @click="deleteFeeding"
            />
          </div>
          <div class="col q-pl-sm">
            <q-btn
              rounded
              :label="`Submit ${Action === 'edit' ? '' : 'Feed'}`"
              color="secondary"
              text-color="black"
              size="md"
              padding="10px md"
              class="full-width"
              type="submit"
            />
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

export default {
  name: 'FeedPond',
  components: {},
  props: {
    Action: {
      type: String,
      required: true
    },
    FeederSelected: {
      type: Object,
      required: true
    },
    Pond: {
      type: Object,
      required: true
    },
    Transaction: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateCopy: null,
      dateCopyStr: null,
      isDateValid,
      feeding: null,
      numberWithCommas,
      overfedDialogSlider: 1,
      parseTimeStamp,
      poundsCopy: null,
      poundsErr: false,
      store,
      transaction: createTransactionObj()
    };
  },
  mounted() {
    this.transaction = {
      ...createTransactionObj(),
      ...cloneObj(this.Transaction)
    };

    this.dateCopy = this.transaction.date;
    this.transaction.date = date.formatDate(
      this.transaction.date * 1000,
      'MM/DD/YYYY'
    );
    this.dateCopyStr = this.transaction.date;
    this.poundsCopy = parseFloat(this.transaction.pounds);
    this.overfedDialogSlider = this.transaction.over_fed;

    if (this.Action === 'edit') {
      this.transaction.pounds = this.transaction.pounds.toString();
    }
  },
  methods: {
    addToPounds(n) {
      this.poundsErr = false;

      if (this.transaction.pounds === null) {
        this.transaction.pounds = '0';
      }

      if (parseInt(this.transaction.pounds) > 99999) {
        return;
      }

      if (this.transaction.pounds.length > 1 && n === 0) {
        this.transaction.pounds += n.toString();
        return;
      }

      this.transaction.pounds = parseFloat(
        this.transaction.pounds + n
      ).toString();
    },
    backspace() {
      if (!this.transaction.pounds) {
        return;
      }

      if (this.transaction.pounds.length === 1) {
        this.transaction.pounds = null;
        return;
      }

      this.transaction.pounds = this.transaction.pounds.slice(0, -1);
    },
    deleteFeeding() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-2',
            textColor: 'primary'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteFeedingConfirmed();
        });
    },
    deleteFeedingConfirmed() {
      const transaction = {
        ...cloneObj(this.transaction),
        storeInfo: { farmId: this.$store.state.farm.id }
      };

      transaction.storeInfo.action = 'deletePondEntry';
      transaction.storeInfo.category = 'pondEntries';
      transaction.storeInfo.pondId = this.Pond.id;
      transaction.storeInfo.pondGrainId = this.Pond.grain_id;

      const growth = cloneObj(this.Pond.growth);
      growth.CurrentUnharvestedFeed =
        parseFloat(growth.CurrentUnharvestedFeed) - transaction.pounds;
      transaction.storeInfo.growth = growth;

      guidMatchHelper(transaction, 'delete');

      // Add details to update feeder info
      const feeder = this.FeederOptions.find(
        (x) => x.value === transaction.from_account
      );

      transaction.storeInfo.feederId = feeder.id;
      transaction.storeInfo.feederGrainId = feeder.value;
      transaction.storeInfo.feederBal =
        parseFloat(feeder.balance) - transaction.pounds;

      store.dispatch('publish', transaction);

      this.$q.notify({
        message: 'Entry Deleted',
        color: 'accent',
        icon: 'delete'
      });

      this.$emit('close');
    },
    submitEntry(notify) {
      if (this.transaction.pounds !== '0' && !this.transaction.pounds) {
        this.poundsErr = true;
        return;
      }
      this.poundsErr = false;

      const transaction = cloneObj(this.transaction);

      transaction.purchaser = this.Pond.purchaser;

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      if (this.Action === 'edit' && this.dateCopyStr === dateStr) {
        transaction.date = this.dateCopy;
      }

      if (this.Action === 'create') {
        transaction.id = 0;
      }

      transaction.to_account = this.Pond.grain_id;
      transaction.from_account = this.FeederSelected.grain_id;
      transaction.pounds = parseFloat(transaction.pounds);
      transaction.over_fed = this.overfedDialogSlider;

      this.submitEntryHelper(transaction);

      store.dispatch('publish', transaction);

      this.$emit('close');

      if (notify) {
        this.$q.notify({
          message: `Entry ${this.Action === 'create' ? 'Added' : 'Updated'}`,
          icon: 'check',
          color: 'primary'
        });
      }
    },
    submitEntryHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table - (mutates transaction)
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries

      transaction.storeInfo.action =
        this.Action === 'create' ? 'createPondEntry' : 'updatePondEntry';

      const growth = cloneObj(this.Pond.growth);
      growth.CurrentUnharvestedFeed =
        parseFloat(growth.CurrentUnharvestedFeed) +
        (transaction.pounds - this.poundsCopy);
      transaction.storeInfo.growth = growth;

      transaction.storeInfo.category = 'pondEntries';
      transaction.storeInfo.pondId = this.Pond.id;
      transaction.storeInfo.pondGrainId = this.Pond.grain_id;

      guidMatchHelper(transaction, this.Action);

      if (this.Action === 'create') {
        transaction.feeder_name = this.FeederSelected.name;
        transaction.feed_type = this.FeederSelected.feedTypeId;
        transaction.feed_type_name = this.FeederSelected.feedTypeName;
      }

      const feeder = this.FeederOptions.find(
        (x) => x.value === transaction.from_account
      );

      // Add details to update feeder info
      transaction.storeInfo.feederId = feeder.id;
      transaction.storeInfo.feederGrainId = feeder.value;
      transaction.storeInfo.feederBal =
        parseFloat(feeder.balance) + (this.poundsCopy - transaction.pounds);
      transaction.pond_name = this.Pond.name;
    }
  },
  computed: {
    FeederOptions() {
      const options = [];
      for (const feeder of store.state.farm.feeders) {
        // get the feed type of the feeder
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of feeder.fillUps) {
          if (entry.feed_type) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: feeder.id,
          balance: feeder.balance,
          label: feeder.name,
          value: feeder.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    PondTFA() {
      if (!this.PondTFADisplay) {
        return '';
      }

      return this.Pond.growth?.targetFeedAmount ?? 0 > 0
        ? numberWithCommas(this.Pond.growth?.targetFeedAmount ?? 0)
        : '0';
    },
    PondTFADisplay() {
      return store.state.farm?.settings?.display_tfa ?? true;
    },
    PoundsDisplay() {
      if (this.transaction.pounds === null) {
        return null;
      }

      return numberWithCommas(parseFloat(this.transaction.pounds));
    },
    SliderDialogColor() {
      return this.overfedDialogSlider === 0
        ? 'secondary'
        : this.overfedDialogSlider === 1
        ? 'primary'
        : 'accent';
    },
    SliderDialogLabel() {
      return this.overfedDialogSlider === 0
        ? 'Satiated'
        : this.overfedDialogSlider === 1
        ? 'No Eval'
        : this.overfedDialogSlider === 2
        ? 'Overfed 1'
        : this.overfedDialogSlider === 3
        ? 'Overfed 2'
        : 'Overfed 3';
    }
  },
  watch: {}
};
</script>

<style></style>
