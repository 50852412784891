<template>
  <div>
    <div>
      <div class="col row items-center" style="padding: 10px 0 10px 0">
        <div
          class="col-7 cursor-pointer"
          @click="$emit('viewPondDetail', Pond)"
        >
          <div class="row items-center">
            <div class="col q-px-sm">
              <span
                style="border-bottom: 1px solid black"
                class="text-subtitle1"
              >
                {{ Pond.name }}
              </span>
            </div>
            <div class="col-7 row items-center">
              <div v-if="LastOxygen" class="col-6 q-pl-sm ellipsis-2-lines">
                {{ LastOxygen.monitor_reading }} -
                {{ LastOxygen.handheld_reading }}
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>

              <div v-if="CurrentOxygen" class="col-6 q-pl-sm ellipsis-2-lines">
                {{ CurrentOxygen.monitor_reading }} -
                {{ CurrentOxygen.handheld_reading }}
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col row items-center">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="oxygenPond()"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Verify</div>
              <div v-if="CurrentOxygen" style="width: 30px; height: 24px">
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Oxygen Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogOxygenPond">
      <OxygenPond
        :Action="'create'"
        :Oxygen="oxygen"
        :Pond="Pond"
        @close="dialogOxygenPond = false"
      />
    </q-dialog>
  </div>
</template>

<script>
import OxygenPond from '@/components/ponds/OxygenPond.vue';

import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultOxygen() {
  return {
    id: null,
    user_id: store.state.user.id,
    monitor_reading: null,
    handheld_reading: null,
    date: +new Date() / 1000,
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'OxygenPondRow',
  components: {
    OxygenPond
  },
  props: {
    Pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      dialogOxygenPond: false,
      errHandheld: false,
      errMonitor: false,
      isDateValid,
      isNumValid,
      modeHandheld: false,
      modeMonitor: false,
      numberWithCommas,
      oxygen: defaultOxygen(),
      parseTimeStamp,
      poundsCopy: null,
      readingHandheld: '',
      readingMonitor: ''
    };
  },
  // mounted() {},
  methods: {
    oxygenPond() {
      this.action = 'create';
      this.oxygen = defaultOxygen();

      this.dialogOxygenPond = true;
    }
  },
  computed: {
    CurrentOxygen() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial oxygen record, return null
      if (this.Pond.oxygenRecords.length === 0) {
        return null;
      }

      // find the most recent oxygen record
      const currOxygen = this.Pond.oxygenRecords.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currOxygenDate = date.formatDate(
        currOxygen.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currOxygenDate ? currOxygen : null;
    },
    LastOxygen() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastOxygen = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.Pond.oxygenRecords.length; i++) {
        const oxygen = this.Pond.oxygenRecords[i];

        const verifyDate = date.formatDate(
          new Date(oxygen.date * 1000),
          'YYYY-MM-DD'
        );

        if (verifyDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastOxygen = cloneObj(oxygen);

          break;
        }
      }

      return lastOxygen;
    },
    ReadingHandheld() {
      let amount = parseFloat(this.readingHandheld);

      const decimalCount = this.readingHandheld.split('.').length - 1;

      if (this.readingHandheld.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.readingHandheld === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    },
    ReadingMonitor() {
      let amount = parseFloat(this.readingMonitor);

      const decimalCount = this.readingMonitor.split('.').length - 1;

      if (this.readingMonitor.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.readingMonitor === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    }
  }
};
</script>

<style></style>
