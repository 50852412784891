<template>
  <div>
    <div v-if="currentView === 'oxygen'">
      <div :style="`height: ${store.state.pageHeight - 50}px`" class="scroll">
        <div class="row justify-center q-px-md q-pt-lg">
          <div
            class="col-12 row items-center text-subtitle2 text-bold q-pt-md"
            style="max-width: 600px"
          >
            <div class="col-12 row items-center q-py-xs">
              <div class="col-7 row">
                <div class="col q-pl-sm">Pond</div>
                <div class="col-7 row no-wrap">
                  <div class="col-6 q-pl-sm">Last</div>
                  <div class="col-auto q-pl-sm">Current</div>
                </div>
              </div>
            </div>

            <q-separator class="full-width" color="black" />
          </div>
        </div>

        <div id="ponds-container" class="scroll q-px-md q-pb-xl">
          <div class="row justify-center">
            <div
              v-for="pond in Ponds"
              :key="pond.name"
              class="col-12 row justify-center"
            >
              <div style="width: 600px">
                <OxygenPondRow :Pond="pond" @viewPondDetail="viewPondDetail" />
                <q-separator />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <OxygenDetail
      v-if="currentView === 'oxygenDetail'"
      :Pond="selectedPond"
      :GoToPond="GoToPond"
      @back="currentView = 'oxygen'"
      @goToNext="goToNext"
      @goToPrevious="goToPrevious"
    />
  </div>
</template>

<script>
import OxygenPondRow from '@/components/ponds/OxygenPondRow.vue';
import OxygenDetail from '@/components/ponds/OxygenDetail.vue';

import { smartCompare, cloneObj } from '@/lib/helpers';
import store from '@/store';

export default {
  name: 'OxygenEntries',
  components: {
    OxygenPondRow,
    OxygenDetail
  },
  data() {
    return {
      currentView: 'oxygen',
      selectedPond: {},
      store
    };
  },
  methods: {
    goToNext() {
      const index = this.Ponds.findIndex(
        (pond) => pond.id === this.selectedPond.id
      );

      this.selectedPond = cloneObj(this.Ponds[index + 1]);
    },
    goToPrevious() {
      const index = this.Ponds.findIndex(
        (pond) => pond.id === this.selectedPond.id
      );

      this.selectedPond = cloneObj(this.Ponds[index - 1]);
    },
    viewPondDetail(pond) {
      this.selectedPond = cloneObj(pond);
      this.currentView = 'oxygenDetail';
    }
  },
  computed: {
    GoToPond() {
      const index =
        this.Ponds.findIndex((pond) => pond.id === this.selectedPond.id) ?? 0;

      const next = index < this.Ponds.length - 1;
      const previous = index > 0;

      return { next, previous };
    },
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  },
  watch: {
    Ponds: {
      handler() {
        const pond = this.Ponds.find(
          (x) => x.id === this.selectedPond?.id ?? null
        );

        if (pond) {
          this.selectedPond = cloneObj(pond);
        }
      },
      deep: true
    }
  }
};
</script>

<style></style>
