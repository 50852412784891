<template>
  <div>
    <div id="adjustable" class="bg-grey-1 shadow-2">
      <div class="row justify-between items-center no-wrap">
        <div
          class="q-py-sm"
          :class="$q.screen.xs ? 'q-pl-sm' : 'q-pl-md'"
          :style="$q.screen.gt.xs && 'width: 162px'"
        >
          <q-btn
            icon="arrow_back"
            color="primary"
            size="md"
            :padding="$q.screen.xs ? '6px 16px' : '6px 20px'"
            @click="$emit('back')"
          />
        </div>

        <div class="q-px-md">
          <div
            class="q-px-md text-center"
            :class="$q.screen.xs ? 'text-subtitle1' : 'text-h6'"
          >
            {{ Pond.name }}
          </div>

          <div class="row justify-center q-pb-sm">
            <q-btn
              v-if="GoToPond.previous || GoToPond.next"
              color="primary"
              icon="chevron_left"
              padding="1px 22px"
              size="md"
              class="q-mr-xs"
              :disabled="!GoToPond.previous"
              @click="$emit('goToPrevious')"
            />

            <q-btn
              v-if="GoToPond.previous || GoToPond.next"
              color="primary"
              icon="chevron_right"
              padding="1px 22px"
              size="md"
              class="q-ml-xs"
              :disabled="!GoToPond.next"
              @click="$emit('goToNext')"
            />
          </div>
        </div>

        <div
          class="row justify-end q-py-sm"
          :class="$q.screen.xs ? 'q-pr-sm' : 'q-pr-md'"
          :style="$q.screen.gt.xs && 'width: 162px'"
        >
          <q-btn
            :label="$q.screen.gt.xs ? 'Oxygen' : ''"
            color="primary"
            icon="add"
            size="md"
            @click="createNewOxygen"
          />
        </div>
      </div>

      <q-separator />
      <div class="row text-bold q-py-xs" :class="$q.screen.gt.xs && 'q-px-sm'">
        <div class="col-auto" style="width: 70px; padding-left: 20px">Date</div>
        <div class="col col-sm-3 text-center">Monitor</div>
        <div class="col col-sm-3 text-center">Handheld</div>
        <div class="lt-sm col-auto text-center" style="width: 60px">Note</div>
        <div class="gt-xs col text-center">Note</div>
        <div style="width: 52px"></div>
      </div>
    </div>

    <div
      v-if="History.length < 1"
      class="text-center text-h5 text-grey-6 q-px-md q-py-lg"
    >
      No Entries For This Pond
    </div>

    <q-virtual-scroll
      v-if="History.length"
      :style="`height: ${store.state.pageHeight - 146}px`"
      :items="History"
      separator
    >
      <template v-slot="{ item }">
        <div
          class="row items-center q-py-sm"
          :class="$q.screen.gt.xs && 'q-px-sm'"
        >
          <div class="col-auto q-pl-sm" style="width: 70px">
            {{ parseTimeStamp(item.date, 'short') }}
          </div>

          <div class="col col-sm-3 text-center" style="word-wrap: break-word">
            {{ item.monitor_reading }}
          </div>

          <div class="col col-sm-3 text-center" style="word-wrap: break-word">
            {{ item.handheld_reading }}
          </div>

          <div class="gt-xs col text-center">
            {{ item.memo }}
          </div>

          <div class="lt-sm q-px-sm" style="width: 60px">
            <q-btn
              v-if="item.memo !== ''"
              unelevated
              icon="sticky_note_2"
              color="grey-3"
              text-color="black"
              padding="2px 10px"
              @click="displayNote(item)"
            />

            <div v-else style="width: 44px"></div>
          </div>

          <div class="q-pr-sm">
            <q-btn
              unelevated
              icon="edit"
              color="grey-3"
              text-color="accent"
              padding="2px 10px"
              @click="editOxygen(item)"
            />
          </div>
        </div>
        <q-separator />
      </template>
    </q-virtual-scroll>

    <!-- Oxygen Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogOxygen">
      <OxygenPond
        :Action="action"
        :Oxygen="oxygen"
        :Pond="Pond"
        @close="dialogOxygen = false"
      />
    </q-dialog>

    <!-- Notes Dialog -->
    <q-dialog position="right" full-hight v-model="dialogNote">
      <q-card style="width: 300px">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(noteDate || '', 'short') }}
            </div>
          </div>
          <q-btn
            unelevated
            icon="close"
            color="grey-3"
            text-color="black"
            size="md"
            padding="2px 10px"
            v-close-popup
          />
        </div>
        <q-separator style="padding-top 1px" />

        <div class="q-pa-sm">
          <span class="text-subtitle2 q-pr-sm"> Note: </span>
          {{ decoder(note) }}
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import OxygenPond from '@/components/ponds/OxygenPond.vue';

import { parseTimeStamp } from '@/lib/date-utils.js';
import { getUID, guidMatchHelper } from '@/store/transactionHelpers';
import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date, uid } from 'quasar';

function defaultOxygen() {
  return {
    id: null,
    user_id: store.state.user.id,
    monitor_reading: null,
    handheld_reading: null,
    date: +new Date() / 1000,
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'OxygenDetail',
  components: {
    OxygenPond
  },
  props: {
    Pond: {
      type: Object,
      required: true
    },
    GoToPond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      decoder,
      dialogOxygen: false,
      dialogNote: false,
      isDateValid,
      isNumValid,
      note: '',
      noteDate: '',
      parseTimeStamp,
      oxygen: defaultOxygen(),
      store
    };
  },
  // mounted() {},
  methods: {
    createNewOxygen() {
      this.action = 'create';
      this.oxygen = defaultOxygen();
      this.dialogOxygen = true;
    },
    displayNote(oxygen) {
      this.note = oxygen.memo;
      this.noteDate = oxygen.date;
      this.dialogNote = true;
    },
    editOxygen(oxygen) {
      this.action = 'update';

      this.oxygen = {
        ...defaultOxygen(),
        ...oxygen
      };

      this.dialogOxygen = true;
    }
  },
  computed: {
    History() {
      return this.Pond.oxygenRecords;
    }
  }
};
</script>

<style></style>
